/** Widgets Styling **/

.widget-info-box.phone .button {
  background: #ff512f;
  background: url(img/dotted-bg.png), linear-gradient(to left, #f93535, #dd2476);
  background: url(img/dotted-bg.png),
    -webkit-linear-gradient(right, #f93535, #dd2476);
}

.widget-info-box.address .button {
  background: #24c6dc;
  background: url(img/dotted-bg.png), linear-gradient(to left, #24c6dc, #514a9d);
  background: url(img/dotted-bg.png),
    -webkit-linear-gradient(right, #24c6dc, #514a9d);
}

.widget-info-box.services .button {
  background: #ff4e50;
  background: url(img/dotted-bg.png), linear-gradient(to left, #f9d423, #ff4e50);
  background: url(img/dotted-bg.png),
    -webkit-linear-gradient(right, #f9d423, #ff4e50);
}

.widget-info-box.website .button {
  background: #8620ce;
  background: url(img/dotted-bg.png), linear-gradient(to left, #d192ff, #8620ce);
  background: url(img/dotted-bg.png),
    -webkit-linear-gradient(right, #d192ff, #8620ce);
}

.map-box {
  margin-top: 30px;
  border-radius: 15px 15px 0 15px;
  overflow: hidden;
  border: 4px solid #8620ce;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
}

/** Profile General **/
#bizzll-business-profile .profile-inner {
  /* max-width: 1100px; */
  width: 100%;
  margin: 0 auto;
}

#profile-page-content {
  display: flex;
  justify-content: space-between;
}

#profile-page-content.animate__animated.animate__fadeIn {
  --animate-duration: 2s;
}

#profile-page-content .bizzll-profile-page-inner {
  width: 65%;
}

#profile-page-content .bizzll-group-page-inner {
  width: 100%;
}

#profile-page-content .profile-page-widgets {
  width: 30%;
}

#profile-page-content .profile-page-widgets .profile-widget {
  margin-bottom: 25px;
}

#profile-page-content .profile-page-widgets .profile-widget button {
  width: 100% !important;
  margin-bottom: 10px;
}

#profile-page-content .profile-page-widgets .profile-widget button span {
  margin-left: 10px;
}

#ajax-lightbox-loader {
  display: none;
  max-width: 90%;
  width: 600px;
  border-radius: 10px;
  overflow: hidden;
}

/** Profile Header **/

#bizzll-profile-header .loading {
  width: 25px;
  margin: 0 auto;
}

/** Profile Subnav **/

#bizzll-profile-submenu {
  background: white;
  border-radius: 15px 15px 0 15px;
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
}

#bizzll-profile-submenu .profile-submenu ul {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  padding-left: 0;
}

#bizzll-profile-submenu .profile-submenu ul,
#bizzll-profile-submenu .profile-submenu ul li {
  list-style-type: none;
}

#bizzll-profile-submenu .profile-submenu ul li a {
  display: flex;
  padding: 10px 25px;
  line-height: 40px;
  font-size: 12pt;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  transition: 0.3s all;
  color: black;
}

#bizzll-profile-submenu .profile-submenu ul li a:hover,
#bizzll-profile-submenu .profile-submenu ul li a.active {
  background: #c63627;
  color: white;
}

#bizzll-profile-submenu .profile-submenu ul li a .menu-icon {
  margin-right: 5px;
}

.header-reviews {
  z-index: 2;
  bottom: 80px;
  right: 0;
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 12pt;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 8px;
}

.header-reviews:hover {
  box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.7);
}
    
.reviews-separator {
  margin: 0 10px;
}
    
.ant-rate {
  font-size: 1rem !important;
}
    
.ant-rate-star {
  margin-right: 4px !important;
}

.ant-rate-star svg {
  font-size: 1.2rem !important;
}

#bizzll-profile-submenu .follower-count {
  margin-right: 10px;
  font-size: 11pt;
  color: #303030;
}

#bizzll-profile-submenu .follower-count span {
  font-weight: 700;
  color: #8620ce;
  font-size: 13pt;
}

/** Profile Channel Box **/

#profile-page-content .featured-box {
  position: relative;
}

#profile-page-content .featured-box h3 {
  height: 50px;
  padding: 10px 15px 10px;
  display: block;
  background: #8620ce;
  color: white;
  margin-bottom: -10px;
}

#profile-page-content .featured-box .featured-description {
  padding: 15px;
  margin-top: -30px;
}

#profile-page-content .featured-box .featured-logo {
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 999;
  opacity: 0.5;
}

#profile-page-content .feature-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px 20px;
}

#profile-page-content .feature-buttons a {
  display: block;
  background: #c63627;
  padding: 15px 20px;
  width: 30%;
  text-align: center;
  color: white;
  font-weight: bold;
  transition: 0.2s all;
  border-radius: 5px;
  font-size: 18px;
  line-height: 25px;
}

#profile-page-content .feature-buttons a:hover {
  transform: scale(1.1);
}

/** Profile About Lightbox **/

.business-about-lightbox,
.business-services-lightbox {
  display: none;
  max-width: 80% !important;
  max-height: 90vh;
  color: #303030;
}

.business-about-lightbox h3.heading,
.business-services-lightbox h3.heading {
  color: #8620ce;
  font-weight: 700;
  border-bottom: 3px #8620ce solid;
  padding-bottom: 10px;
  max-width: 500px;
  display: block;
  margin: 0 auto 10px;
  text-align: center;
  font-size: 16pt;
}

.business-about-lightbox p {
  margin-bottom: 15px;
}

/** Profile Events **/

#bizzll-business-profile .events-list .profile-page-title {
  margin-bottom: 20px;
}

#bizzll-business-profile .events-list ul,
#bizzll-business-profile .events-list ul li {
  list-style-type: none;
}

#bizzll-business-profile .events-list ul li {
  margin-bottom: 15px;
}

#bizzll-business-profile .events-list a {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
  color: white;
  text-decoration: none !important;
  transition: 0.3s all;
  border-left: none;
}

#bizzll-business-profile .events-list a:hover {
  border-left: 5px solid black;
}

#bizzll-business-profile .events-list a h3 {
  color: white;
}

#bizzll-business-profile .event-date {
  display: flex;
  font-weight: 700;
  margin-bottom: 10px;
}

#bizzll-business-profile .event-date .date-left {
  font-size: 40pt;
  border-right: 2px solid white;
  padding-right: 5px;
  line-height: 45px;
}

#bizzll-business-profile .event-date .date-right {
  padding-left: 5px;
}

#bizzll-business-profile .event-date .month {
  display: block;
  font-size: 16pt;
}

#ajax-lightbox-loader .single-event-wrapper .feature-image {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  background-size: cover;
  margin-bottom: 15px;
}

#ajax-lightbox-loader .single-event-wrapper .event-desc {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bebebe;
}

#ajax-lightbox-loader .single-event-wrapper .event-meta {
  display: flex;
  justify-content: center;
}

#ajax-lightbox-loader .single-event-wrapper .event-meta .date-time {
  text-align: right;
  margin-right: 20px;
  margin-bottom: 10px;
}

#ajax-lightbox-loader .single-event-wrapper .share-event {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  justify-content: space-between;
  font-size: 14pt;
  background: #ebebeb;
}

#ajax-lightbox-loader .single-event-wrapper .share-event a {
  margin-left: 5px;
  font-size: 16pt;
}

/** Checkout popup **/

#ajax-lightbox-loader .loader {
  display: block;
  margin: 0 auto;
  width: 70px;
  height: 70px;
}

/** Profile Reviews **/

#bizzll-business-profile .reviews-list .profile-page-title {
  margin-bottom: 20px;
}

#bizzll-business-profile .reviews-list ul,
#bizzll-business-profile .reviews-list ul li {
  list-style-type: none;
}

#bizzll-business-profile .reviews-list .review-wrapper {
  margin-bottom: 20px;
  border: 2px solid #f5f5f5;
  background: white;
}

#bizzll-business-profile .reviews-list .poster-info {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #f5f5f5;
}

#bizzll-business-profile .reviews-list .poster-info .logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #8620ce;
  margin-right: 10px;
}

#bizzll-business-profile .reviews-list .poster-info .name a,
#bizzll-business-profile .reviews-list .poster-info .name {
  color: #8620ce;
  font-size: 12pt;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 5px;
}

#bizzll-business-profile .reviews-list .poster-info .rating {
  justify-self: self-end;
  margin-left: auto;
}

#bizzll-business-profile .reviews-list .poster-info .stars {
  display: flex;
}

#bizzll-business-profile .reviews-list .poster-info .star {
  font-size: 16pt;
  margin: 0 2px;
  text-align: center;
  display: block;
}

#bizzll-business-profile .reviews-list .poster-info .star.star-full {
  color: #fcc204 !important;
}

#bizzll-business-profile .reviews-list .date-posted {
  color: #303030;
  font-size: 9pt;
}

#bizzll-business-profile .reviews-list .review-message {
  padding: 15px;
  font-style: italic;
}



.message-txt {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.message-txt svg {
  cursor: pointer;
}

.ant-dropdown {
  position: fixed;
}

.message-deleted {
  color: black;
  background-color: #d7d7d7;
  font-size: 12px;
  border-radius: 5px;
  padding: 7px;
  font-style: italic;
}

.post-heading {
  /* padding: 30px 20px; */
  color: #8b8b94;
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  /* margin-bottom: 20px; */
  text-transform: uppercase;
}

.post-heading span {
  width: 48px;
  height: 48px;
  color: #8b8b94;
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  margin-right: 16px;
  background: #f5f5f5;
  display: inline-block;
}

.post-content {
  padding: 20px;
  padding-bottom: 0;
}

.btn-right {
  float: right;
}

.btn-left {
  float: left;
}

.add-post-btn {
  background: #8620ce;
  color: white;
  border: none;
  border-radius: 5px 5px 0 5px;
}

.add-post-btn:hover {
  background: #8620ce;
  color: white;
  border: none;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
}

.add-post-btn:focus {
  background: #8620ce;
  color: white;
  border: none;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
}

.all-advertisements {
  margin-top: 20px;
}

.pre-content {
  margin-top: 60px;
}

.all-advertisements {
  margin-top: 60px;
  /* max-height: 500px;
  overflow: auto; */
}

.single-post-container {
  background: white;
  height: 100%;
  border: 1px solid #dbd9d9;
  border-radius: 15px 15px 0 15px;
  padding: 10px;
  margin: auto;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
  margin-bottom: 20px;
}

.post-header-name {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 400px;
}

.replyTimeText {
  font-size: 10px;
}

.replyToUserText {
  color: #fff;
}

.post-header {
  display: flex;
  margin-top: 10px;
  margin-left: 18px;
}

.post-owner-name {
  font-size: 12px;
}

.post-time {
  font-size: 10px;
  overflow-wrap: anywhere;
}

.businessComment {
  font-size: 14px;
  margin-top: 10px;
}

.commentReportContainer {
  float: right;
  font-size: 14px;
}

.wall-text {
  margin-bottom: 20px;
}

.post-like-comment {
  display: flex;
  justify-content: space-between;
}

.add-comment {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

.add-comment svg {
  cursor: pointer;
}

.post-comment svg {
  color: #afafaf;
  font-size: 13px;
  cursor: pointer;
}

.post-like svg {
  color: #afafaf;
  font-size: 13px;
  margin-right: 10px;
}

.post-like-active svg {
  color: #8620ce;
  font-size: 13px;
  margin-right: 10px;
}

.post-like-comment svg:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.post-like-comment h3 {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.post-form-input {
  border-radius: 10px;
  margin-top: 20px;
  background: #ebe0f1;
}

.post-form-input Input {
  background: #ebe0f1;
}

.post-owner-name span {
  margin-left: 10px;
  font-size: 10px;
}

.comment-header {
  display: flex;
  margin-top: 10px;
}

.comment {
  background: #ebe0f1;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  padding-bottom: 3px;
}

.commentReply {
  background: #bdc3c7;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  padding-bottom: 3px;
  margin-top: 10px;
}

.post-action {
  margin-left: 63%;
  cursor: pointer;
}

.post-content hr {
  border-top: 1px solid #ccc;
}

.menu-text {
  color: #c63627;
}

.delete-ad-btn {
  background: #8620ce;
  border-color: #8620ce;
}

.delete-ad-btn:hover {
  background: #8620ce;
  border-color: #8620ce;
}

.cancel-ad-btn:hover {
  color: #8620ce;
  border-color: #8620ce;
}

.likes-container {
  max-height: 300px;
  overflow: auto;
}

.likes-list {
  display: flex;
  margin-bottom: 10px;
}

.like-username {
  margin-top: 5px;
  margin-left: 10px;
}

.comment-modal {
  max-height: 300px;
  overflow: auto;
}

.recent-comments {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.post-edited {
  display: inline-block;
  margin-left: 7px;
  color: #8620ce;
}

.uplaod-image-container {
  height: 200px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 250px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid grey;
  border-style: dashed;
}

.video-player {
  position: relative;
}

.post-upload-image {
  max-width: 200px;
  max-height: 130px;
  position: relative;
}

.uplaod-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
}

.post-media {
  text-align: center;
}

.post-attachments {
  width: 100%;
  height: auto;
  max-height: 300px;
}

.share-menu {
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: flex-start;
}

.share-platform-text {
  margin-left: 10px;
  margin-top: 5px;
}

.comment-delete {
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c63627;
  border: 1px solid #c63627;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  align-self: center;
}

.comment-delete:hover {
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: white;
  color: #c63627;
}

.comment-delete svg {
  width: 100%;
  font-size: 12px;
}

.add-comment Input {
  border: none !important;
}

.cartAffix {
  float: right;
}

.cartAffixAfter .ant-affix {
  width: 300px;
  float: right;
}

.ant-badge-count {
  margin-right: 0px;
}

.cartBtnContainer {
  display: flex;
}

.cartBtn {
  font-size: 30px;
  height: 54px;
  background: purple;
  color: #fff;
  border-style: none;
}

.cartBtnRightTitle {
  font-size: 24px;
  padding: 10px 0 0 20px;
  height: 54px;
  width: 237px;
  background: purple;
  color: #fff;
}

.crtButton {
  position: fixed;
  top: 80px;
  right: 0px;
  z-index: 100;
}

.selectedCartDataList {
  padding: 10px;
  border-bottom: 1px solid grey;
}

.selectedCartDataList:hover {
  background-color: #fff;
}

.cartEmpty {
  font-size: 24px;
}

.selectedCartDetailsList {
  padding: 10px;
  border-bottom: 1px solid grey;
}

.cartItemsListContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.cartMenuName {
  display: block;
  font-weight: bold;
}

.cartMenuName:hover {
  border-bottom: 1px solid rgba(100, 100, 111, 0.2);
}

.cartMenuPrice {
  display: block;
  text-align: right;
}

.cartMenuAddonName {
  display: block;
  font-size: smaller;
}

.totalQuantity {
  padding: 0 6px;
  font-size: smaller;
  background-color: white;
  border: 1px solid gainsboro;
}

.cartItemName {
  margin: 10px 0 0 0;
  width: 100%;
}

#orderDetailsConatiner {
  margin: 0 auto;
  max-width: 60%;
  background-color: #ffffa2;
  padding: 2%;
}

.unverified-stripe-acc-message {
  text-align: center;
  color: #c63627;
}

/* Service Page Styles */

.service_container .steps-menu {
  background-color: white;
  min-width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid ;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  margin-bottom: 15px;
}

.service_container .service_container-services {
  background-color: white;
  min-width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  display: flex;
  flex-wrap: wrap;
  place-content: space-between;
}

.single-service-container {
  width: 48%;
  margin: 1%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  display: flex;
  position: relative;
  border-radius: 15px 15px 0 15px;
  float: inline-start;
}

.single-service-container_select {
  width: 100%;
  background-color: transparent;
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
}

.selected_service {
  border-color: #c63627 !important;
  box-shadow: 0 8px 16px rgba(114, 124, 149, .12), 0 4px 8px rgba(114, 124, 149, .12);
}

.single-service-container_image {
  width: 20%;
  overflow: hidden;
  margin-right: 10px;
}

.single-service-container_image img{
  width: 100%;
}

.single-service-container_data {
  width: 80%;
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.single-service-container_data-row-1 label{
  font-size: 12pt;
  font-weight: bold;
}

.single-service-container_data-row-2 {
  display: flex;
  font-size: 11pt;
}

.single-service-container_data-row-2 .single-service-container_data-row-2-duration {
  margin-right: 15px;
}

.single-service-container_data-row-2 .single-service-container_data-row-2-duration .single-service-container_data-row-2-duration-label {
  font-weight: bold;
}

.single-service-container_data-row-2 .single-service-container_data-row-2-price-label {
  background-color: #c63627;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
  color: white;
}

.service_container-info {
  background-color: white;
  min-width: 100%;
  padding: 25px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
}

.service_container-info label {
  font-size: 16px;
}

.service_container-info input {
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 15px 15px 0 15px;
}

.service_container-info textarea {
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 15px 15px 0 15px;
}

.service_container-calendar {
  background-color: white;
  min-width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  display: flex;
  place-content: space-between;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-picker-calendar-header {
  justify-content: center;
}

.service_container-calendar .ant-picker-calendar-date-content {
  display: none;
}

.service_container-calendar table {
  text-align: center;
}container_select

.service_container-calendar .ant-picker-calendar-date {
  padding: 4px 8px !important;
}

.service_container-calendar th {
  padding: 4px 8px !important;
  font-weight: bold;
}

.service_container-calendar_calendar {
  width: 50%;
  padding: 15px;
}

.service_container-calendar_turn {
  width: 50%;
  margin: 15px;
  border: 1px solid #CFD6E5 !important;
  border-radius: 5px;
  padding: 10px;
  max-height: 348px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.single-turn-container {
  width: 46%;
  padding: 10px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  display: flex;
  position: relative;
  float: inline-start;
  margin: 2%;
  text-align: center;
  height: 64px;
}

.single-turn-container_select {
  width: 100%;
  background-color: transparent;
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
}

.single-turn-container_data {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.selected_turn {
  border-color: #c63627 !important;
  box-shadow: 0 8px 16px rgba(114, 124, 149, .12), 0 4px 8px rgba(114, 124, 149, .12);
}

.service_container-confirmation {
  background-color: white;
  min-width: 100%;
  padding: 25px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
}

.service_container-confirmation h3 {
  text-align: center;
}

.service_container-confirmation_container {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.service_container-confirmation_service_and_date {
  display: flex;
  width: fit-content;
  margin: auto;
}

.single-service-container-cart {
  width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  display: flex;
  position: relative;
}

.single-service-container-cart_image {
  width: 30%;
  overflow: hidden;
  margin-right: 10px;
}

.single-service-container-cart_data {
  width: 65%;
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.single-service-container-cart_delete {
  width: 5%;
  font-size: 20px;
  align-self: center;
}

@media (max-width: 900px) {
  .service_container-calendar {
    flex-direction: column;
    
    .service_container-calendar_calendar {
      width: 100%;
    }

    .service_container-calendar_turn {
      width: 100%;
      margin: 0;
    }
  }
}

@media (max-width: 767px) {
  #bizzll-profile-submenu {
    flex-direction: column;

    .header-reviews {
      margin: 10px;
    }
  }
}

@media (max-width: 700px) {
  .single-service-container_data-row-2 {
    flex-direction: column;
  }
}

/* Restaurant page styles */

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  background: white !important; /* $lt-grey */
  border-color: #0099d9 !important; /* $md-grey */
  border-width: 2px !important;
  font-size: 1.1rem !important;
  border-bottom: #d3d3d3 1px solid !important;
  border-radius: 8px 8px 0 0 !important;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover {
  background-color: white !important;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #0099d9 !important; /* $hd-grey */
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #0099d9 !important;
  color: white !important;
  border-color: #0099d9 !important;
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.ant-tabs-nav::before {
  border-color: #f5f5f5 !important; /* $light-bg */
}


.menuItem_container .steps-menu {
  background-color: white;
  min-width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  margin-bottom: 15px;
}

.menuItem_container .menuItem_container-menuItems {
  background-color: white;
  min-width: 100%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  border: 1px solid;
  border-radius: 5px;
  border-color: #CFD6E5 !important;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px 15px 0 15px;
}

.menu-category {
  width: 100%;
}

.menu-category-title {
  text-align: center;
}

.single-menuItem-container {
  width: 48%;
  margin: 1%;
  padding: 15px;
  overflow-x: auto;
  box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12);
  display: flex;
  position: relative;
  border-radius: 15px 15px 0 15px;
  float: inline-start;
}

.single-menuItem-container_select {
  width: 100%;
  background-color: transparent;
  border: 1px solid;
  border-radius: 15px 15px 0 15px;
  border-color: #CFD6E5 !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
}

.single-menuItem-container_image {
  width: 20%;
  overflow: hidden;
  margin-right: 10px;
}

.single-menuItem-container_image img {
  width: 100%;
}

.single-menuItem-container_data {
  width: 80%;
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.single-menuItem-container_data-row-1 label {
  font-size: 12pt;
  font-weight: bold;
}

.single-menuItem-container_data-row-2 {
  display: flex;
  font-size: 11pt;
}

.single-menuItem-container_data-row-2 .single-menuItem-container_data-row-2-price-label {
  background-color: #c63627;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
  color: white;
}

.single-menuItem-container-cart {
  width: 100%;
  padding: 15px;
  overflow-x: auto;
  /* box-shadow: 0 2px 6px rgba(166, 176, 196, .04), 0 4px 12px rgb(166, 176, 196, .12); */
  display: flex;
  position: relative;
  box-shadow: 0 0 4px rgba(1, 1, 1, 0.3);
  border-radius: 15px 15px 0 15px;
  margin: 0 0 15px 0;
}

.ant-drawer-header p {
  margin: 0;
}

.single-menuItem-container-cart_image {
  width: 20%;
  overflow: hidden;
  margin-right: 10px;
}

.single-menuItem-container-cart_data {
  width: 55%;
  display: flex;
  flex-direction: column;
  place-content: space-around;
}

.single-menuItem-container-cart_quantity {
  width: 15%;
  text-align: center;
  align-self: center;
}

.single-menuItem-container-cart_quantity .ant-input-number {
  width: 60px;
}

.single-menuItem-container-cart_delete {
  width: 10%;
  font-size: 20px;
  align-self: center;
  text-align: center;
}

.single-menuItem-container-cart_delete:hover {
  font-size: 24px;
}

/* Restaurant modal style */

.restaurant_modal .ant-modal-body {
  padding: 0;
}

.restaurant_modal .ant-modal-body .modal_container {
  display: flex;
}

.restaurant_modal .ant-modal-body .modal_image {
  width: 50%;
}

.restaurant_modal .ant-modal-body .modal_image img {
  width: 100%;
}

.restaurant_modal .ant-modal-body .modal_data {
  width: 50%;
  padding: 45px;
}

.restaurant_modal .ant-modal-body .modal_data h3 {
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 27px;
}

.restaurant_modal .ant-modal-body .modal_data h5 {
  font-size: 22px;
}

.restaurant_modal .ant-modal-body .ant-input-number-input-wrap {
  width: 60px;
}

/* Cart icon style*/

.fa-cart-shopping {
  position: fixed;
  right: 8px;
  top: 50%;
  z-index: 998;
  width: 35px;
  height: 35px;
  background-color: #c63627;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  border-radius: 0;
  cursor: pointer;
  border: 2px solid #c63627;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 10px;
}

.fa-cart-shopping:hover {
  background: white;
  color: #c63627;
  border-color: #c63627;
}

.cart_quantity {
  position: fixed;
  right: 2px;
  top: 48.5%;
  z-index: 999;
  width: 27px;
  height: 27px;
  background-color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
  border-radius: 0;
  cursor: pointer;
  border: 1px solid #1890ff;
  color: #1890ff;
  border-radius: 30px;
  text-align: center;
  padding: 2px;
  font-weight: bold;
}