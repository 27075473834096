#bizzll-directory {
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: $lt-grey !important;
        border-color: $md-grey !important;
        border-width: 2px !important;
        font-size: 1.1rem !important;
        border-bottom: $lt-grey 1px solid !important;
        border-radius: 8px 8px 0 0 !important;

        &:hover {
          background-color: white !important;

          .ant-tabs-tab-btn {
            color: $hd-grey !important;
          }
        }

        &.ant-tabs-tab-active {
          background-color: #0099d9 !important;
          color: white !important;
          border-color: #0099d9 !important;

          .ant-tabs-tab-btn {
            color: white !important;
          }
        }
      }
    }

    &::before {
      border-color: $light-bg !important;
    }
  }

  #directory-listing {
    height: 763px;
    overflow-y: auto;
    padding-right: 10px;

    .listing-item {
      background: white;
      margin-bottom: 30px;
      border-radius: 15px 15px 0 15px;
      overflow: hidden;
      transition: 0.3s all;
      display: flex;
      position: relative;
      justify-content: space-between;
      border: 2px solid #0099d9;
      box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);

      .cover-img {
        background-size: cover;
        overflow: hidden;
        position: relative;
        background-position: center;
        height: 250px;
        width: 250px;
        margin: 0;

        .image {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: cover;
          transition: 0.3s all;
          background-position: center;
        }
      }

      .business-info {
        width: 70%;
        padding: 15px;
        border-left: 1px solid $lt-grey;
        position: relative;

        .top-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;

          .business-name {
            width: 60%;

            h3 {
              display: block;
              width: 100%;
              text-decoration: none;
              font-weight: 700;
              font-size: 1rem;
            }
          }

          .top-row-meta {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .bizzll-badge {
              background: #fcc204;
              border-radius: 10px;
              padding: 3px;
              width: 50px;
            }

            .reviews {
              margin-right: 5px;

              ul {
                margin: 0 !important;
              }

              .ant-rate-star {
                margin: 2px !important;

                .ant-rate-star-second {
                  svg {
                    color: $md-grey !important;
                  }
                }

                &.ant-rate-star-half {
                  .ant-rate-star-first svg {
                    color: $red !important;
                  }
                }

                &.ant-rate-star-full {
                  svg {
                    color: $red !important;
                  }
                }
              }

              svg {
                font-size: 1rem !important;
              }
            }

            .claim-business-btn {
              font-size: 1rem;
            }
          }
        }

        .business-description {
          display: flex;
          justify-content: space-between;

          p {
            font-size: 0.9rem;
            line-height: 1.5em;
            margin-bottom: 0;
            display: block;
            width: 60%;
          }

          .business-meta {
            width: 40%;
            text-align: right;
            line-height: 1.5em;

            .phone {
              font-size: 1.2rem;
              margin-bottom: 5px;
              font-weight: 600;
            }
          }
        }

        .bottom-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 15px;

          .business-category {
            width: 60%;

            .category-box {
              background: #ececec;
              border-radius: 5px;
              padding: 10px;
              display: inline-block;
              color: #434b57;
              font-weight: 600;
              line-height: 1.5em;
              font-size: 0.8rem;
              text-transform: uppercase;
            }
          }

          .listing-buttons {
            display: flex;
            width: 50%;
            justify-content: flex-end;

            .ant-btn {
              width: 45px !important;
              height: 45px !important;
              margin-left: 10px;
            }
          }
        }
      }

      &:hover {
        -webkit-box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);
        box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);
      }
    }

    .ant-pagination {
      display: flex;
      place-content: center;
      margin-left: 0;
    }
  }

  @include tablet-below {
    .page-sidebar {
      display: none;
    }

    .page-content {
      width: 100%;
    }
  }

  @include mobile-size {
    .listing-header {
      flex-direction: column-reverse;

      .sort-form {
        margin-bottom: 15px;
        flex-direction: column;

        form {
          margin-bottom: 5px;
        }
      }
    }

    .listing-item {
      display: block !important;
      width: 300px;
      margin: 0 auto 30px !important;

      .cover-img {
        margin-bottom: 30px;
        border-bottom: 1px solid #ebebeb;
        width: 300px !important;
        height: 300px !important;
      }

      .business-info {
        border: none !important;
        width: 100% !important;

        .top-row {
          flex-direction: column-reverse;
          align-items: center;
          text-align: center;
          margin-bottom: 0 !important;

          .business-name,
          .top-row-meta {
            width: 100% !important;
            justify-content: center !important;
          }
        }

        .business-description {
          flex-direction: column;
          align-items: center !important;

          p,
          .business-meta {
            width: 100% !important;
            text-align: center !important;
          }

          .business-meta {
            border: $lt-grey 1px solid;
            margin: 15px 10px;
            padding: 10px;
          }
        }

        .bottom-row {
          position: static !important;
          flex-direction: column;
          justify-content: center;

          .business-category,
          .listing-buttons {
            width: 100% !important;
            justify-content: center !important;
            text-align: center !important;
          }

          .listing-buttons {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
